/* eslint-disable no-script-url */
import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { compose } from 'recompose';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faInstagram,
    faTwitter,
    faFacebook,
    faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import * as ROUTES from 'constants/routingPaths';
import logoSmall from 'assets/images/logo-small-gray.webp';

import withAuthUserContext from 'hoc/withAuthUserContext';
import { decodeUrl } from 'lib/seo.utils';

const navigation = {
    product: [
        {
            name: 'Explorateur de ventes',
            href: ROUTES.TRANSACTION_EXPLORER_SEARCH,
        },
        {
            name: 'Analyseur de biens',
            href: ROUTES.PROPERTY_EXPLORER_SEARCH,
            onAuthenticated: true,
        },
        { name: 'Marché immobilier', href: ROUTES.MARKET_EXPLORER },
        {
            name: "Logiciel d'estimation immobilière",
            href: ROUTES.APPRAISAL_SOFTWARE,
        },
        { name: 'API immobilière', href: ROUTES.PRODUCT_API },
    ],
    info: [
        { name: 'DVF', href: ROUTES.DVF },
        { name: 'Base Patrim', href: ROUTES.PATRIM },
        { name: 'Cadastre en ligne', href: ROUTES.BLOG_CADASTRE },
        { name: 'Estimation bien immobilier', href: ROUTES.PROPERTY_VALUATION },
    ],
    about: [
        { name: 'Contact', hiddenLink: ROUTES.CONTACT_BASE64 },
        { name: 'CGU', hiddenLink: ROUTES.CGU_BASE64 },
        { name: 'CGV', hiddenLink: ROUTES.CGV_BASE64 },
        { name: 'Mentions légales', hiddenLink: ROUTES.LEGAL_TERMS_BASE64 },
        { name: 'Actualités', href: ROUTES.BLOG_HOME },
        { name: 'Cookies', href: 'javascript:showAxeptioButton()' },
    ],
    social: [
        {
            name: 'Facebook',
            href: 'https://www.facebook.com/ImmoDataFR',
            icon: faFacebook,
        },
        {
            name: 'Instragam',
            href: 'https://www.instagram.com/immo.data',
            icon: faInstagram,
        },
        {
            name: 'Linkedin',
            href: 'https://www.linkedin.com/company/immodata',
            icon: faLinkedin,
        },
        {
            name: 'Twitter',
            href: 'https://twitter.com/immo_data',
            icon: faTwitter,
        },
    ],
};

class Footer extends Component {
    render() {
        if (
            this.props.location.pathname === ROUTES.LOGIN ||
            this.props.location.pathname === ROUTES.SIGN_UP ||
            this.props.location.pathname === ROUTES.PASSWORD_FORGET ||
            this.props.location.pathname === ROUTES.ACTION ||
            this.props.location.pathname === ROUTES.PAYMENT_CHECKOUT ||
            this.props.location.pathname === ROUTES.WELCOME ||
            this.props.location.pathname === ROUTES.PROSPECTS ||
            this.props.location.pathname === ROUTES.LEADS ||
            this.props.location.pathname.startsWith(ROUTES.ACCOUNT) ||
            this.props.location.pathname.startsWith(ROUTES.ACCESS_MANAGEMENT) ||
            this.props.location.pathname.startsWith(
                '/explorateur/transaction'
            ) ||
            this.props.location.pathname.startsWith('/explorateur/bien') ||
            this.props.location.pathname.startsWith('/analyseur/marche') ||
            this.props.location.pathname.startsWith(ROUTES.VALUATION_WIDGET) ||
            this.props.location.pathname.startsWith('/rapport/') ||
            this.props.location.pathname.startsWith('/estimation/') ||
            this.props.location.pathname.startsWith(ROUTES.SHOWCASE)
        )
            return null;

        const { authUser } = this.props.context;
        return (
            <footer
                className="bg-primary-900 top-full"
                aria-labelledby="footer-heading"
            >
                <p id="footer-heading" className="sr-only">
                    Footer
                </p>
                <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                    <div className="lg:grid lg:grid-cols-3 gap-8">
                        <div className="text-gray-300">
                            <div className="w-40">
                                <Link to={ROUTES.HOME}>
                                    <img
                                        src={logoSmall}
                                        alt="Logo Immo Data"
                                        loading="lazy"
                                    />
                                </Link>
                            </div>
                            <div className="mt-2 text-sm">
                                <p>
                                    Immo Data est la plateforme qui réunit pour
                                    vous toutes les informations immobilières
                                    dont vous avez besoin.
                                </p>
                                <p>
                                    Parcourez des millions de ventes
                                    immobilières, et générez des analyses
                                    détaillées pour les biens de votre choix.
                                </p>
                            </div>
                        </div>
                        <div className="md:grid md:grid-cols-2 md:gap-8 mt-12 lg:mt-0">
                            <div>
                                <p className="section-title-sm">
                                    Professionnels
                                </p>
                                <ul className="mt-4 space-y-1">
                                    {navigation.product.map(item => (
                                        <li
                                            key={item.name}
                                            className={
                                                item.onAuthenticated &&
                                                !authUser
                                                    ? 'hidden'
                                                    : ''
                                            }
                                        >
                                            <Link
                                                to={item.href}
                                                className="text-sm text-gray-300 hover:text-white"
                                            >
                                                {item.name}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="mt-12 md:mt-0">
                                <p className="section-title-sm">Informations</p>
                                <ul className="mt-4 space-y-1">
                                    {navigation.info.map(item => (
                                        <li key={item.name}>
                                            <Link
                                                to={item.href}
                                                className="text-sm text-gray-300 hover:text-white"
                                            >
                                                {item.name}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="md:grid md:grid-cols-2 md:gap-8 mt-12 lg:mt-0">
                            <div>
                                <p className="section-title-sm">À propos</p>
                                <ul className="mt-4 space-y-1">
                                    {navigation.about.map(item => (
                                        <li key={item.name}>
                                            {item.name === 'Cookies' ? (
                                                <span
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                            '<a href="' +
                                                            item.href +
                                                            '" class="text-sm text-gray-300 hover:text-white">' +
                                                            item.name +
                                                            '</a>',
                                                    }}
                                                />
                                            ) : item.href ? (
                                                <Link
                                                    to={item.href}
                                                    className="text-sm text-gray-300 hover:text-white"
                                                >
                                                    {item.name}
                                                </Link>
                                            ) : (
                                                <span
                                                    onClick={() =>
                                                        decodeUrl(
                                                            item.hiddenLink
                                                        )
                                                    }
                                                    className="text-sm text-gray-300 hover:text-white cursor-pointer"
                                                >
                                                    {item.name}
                                                </span>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="mt-12 md:mt-0 flex flex-col gap-y-6">
                                <div>
                                    <p className="section-title-sm">
                                        Suivez-nous
                                    </p>
                                    <div className="grid grid-cols-4 mt-4">
                                        {navigation.social.map(item => (
                                            <a
                                                key={item.name}
                                                href={item.href}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                aria-label={item.name}
                                            >
                                                <FontAwesomeIcon
                                                    icon={item.icon}
                                                    className="text-gray-300"
                                                    size="2x"
                                                />
                                            </a>
                                        ))}
                                    </div>
                                </div>
                                <div>
                                    <Link
                                        to={ROUTES.SITEMAP}
                                        className="text-sm text-gray-300 hover:text-white"
                                    >
                                        Plan du site
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default compose(withRouter, withAuthUserContext)(Footer);
