import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import loadable from '@loadable/component';
import * as ROUTES from 'constants/routingPaths';

const Product = loadable(() => import('scenes/Product/Product'));
const Home = loadable(() => import('scenes/Home/Home'));
const HomePro = loadable(() => import('scenes/Home/HomePro'));
const Dvf = loadable(() => import('scenes/Blog/Dvf/Dvf'));
const Patrim = loadable(() => import('scenes/Blog/Patrim/Patrim'));

const ValuationForm = loadable(() =>
    import('scenes/ValuationForm/ValuationForm')
);

const BlogPost = loadable(() => import('scenes/Blog/BlogPost'));
const BlogHome = loadable(() => import('scenes/Blog/BlogHome'));

const CadastreContent = loadable(() =>
    import('scenes/Blog/OnlineCadastre/OnlineCadastre')
);

const NpsThanks = loadable(() => import('scenes/Nps/NpsThanks'));
const NpsFeedback = loadable(() => import('scenes/Nps/NpsFeedback'));
const AppraisalSoftware = loadable(() =>
    import('scenes/AppraisalSoftware/AppraisalSoftware')
);
const PropertyValuation = loadable(() =>
    import('scenes/PropertyValuation/PropertyValuation')
);

const Cgu = loadable(() => import('scenes/Terms/Cgu'));
const Cgv = loadable(() => import('scenes/Terms/Cgv'));
const LegalTerms = loadable(() => import('scenes/Terms/LegalTerms'));

const Country = loadable(() =>
    import('scenes/Product/MarketExplorer/GeoLevels/Country')
);
const Region = loadable(() =>
    import('scenes/Product/MarketExplorer/GeoLevels/Region')
);
const Department = loadable(() =>
    import('scenes/Product/MarketExplorer/GeoLevels/Department')
);
const City = loadable(() =>
    import('scenes/Product/MarketExplorer/GeoLevels/City')
);
const District = loadable(() =>
    import('scenes/Product/MarketExplorer/GeoLevels/District')
);
const Street = loadable(() =>
    import('scenes/Product/MarketExplorer/GeoLevels/Street')
);
const Address = loadable(() =>
    import('scenes/Product/MarketExplorer/GeoLevels/Address')
);

const Contact = loadable(() => import('scenes/Contact/Contact'));
const ProductExplorer = loadable(() =>
    import('scenes/ProductLandingPages/ProductExplorer')
);
const ProductAnalyzer = loadable(() =>
    import('scenes/ProductLandingPages/ProductAnalyzer')
);
const ProductWidget = loadable(() =>
    import('scenes/ProductLandingPages/ProductWidget')
);
const ProductShowcase = loadable(() =>
    import('scenes/ProductLandingPages/ProductShowcase')
);
const ProductMarketAnalyser = loadable(() =>
    import('scenes/ProductLandingPages/ProductMarketAnalyser')
);
const ProductProspect = loadable(() =>
    import('scenes/ProductLandingPages/ProductProspect')
);
const ProductApi = loadable(() =>
    import('scenes/ProductLandingPages/ProductApi')
);

const Pricing = loadable(() => import('scenes/Pricing/Pricing'));
const Login = loadable(() => import('scenes/AuthManagement/Login/Login'));
const SignUp = loadable(() => import('scenes/AuthManagement/SignUp/SignUp'));
const PasswordForget = loadable(() =>
    import('scenes/AuthManagement/PasswordForget/PasswordForget')
);
const ActionHandler = loadable(() =>
    import('scenes/AuthManagement/ActionHandler/ActionHandler')
);
const PaymentCheckout = loadable(() =>
    import('scenes/Payment/PaymentCheckout/PaymentCheckout')
);
const Account = loadable(() => import('scenes/Account/Account'));
const Welcome = loadable(() => import('scenes/Welcome/Welcome'));
const AccessManagement = loadable(() =>
    import('scenes/AccessManagement/AccessManagement')
);
//LANDING PAGES
const IndependantAgent = loadable(() =>
    import('scenes/LandingPage/IndependantAgent')
);
const RealEstateAgent = loadable(() =>
    import('scenes/LandingPage/RealEstateAgent')
);
const EmbedDvf = loadable(() => import('scenes/LandingPage/EmbedDvf'));

const ShowcaseForm = loadable(() =>
    import('scenes/Product/Showcase/ShowcaseSetup/ShowcaseForm')
);
const AgentsDirectory = loadable(() =>
    import('scenes/AgentsDirectory/AgentsDirectory')
);
const AgentsDirectoryResults = loadable(() =>
    import('scenes/AgentsDirectory/AgentsDirectoryResults')
);

const PublicShowcase = loadable(() =>
    import('scenes/Product/Showcase/PublicShowcase')
);

const ShowcaseReview = loadable(() =>
    import('scenes/Product/Showcase/ShowcaseReview')
);

const Sitemap = loadable(() => import('scenes/Sitemap/Sitemap'));
const RedirectPage = loadable(() =>
    import('components/RedirectPage/RedirectPage')
);
const ErrorPage = loadable(() => import('components/ErrorPage/ErrorPage'));

const Routes = () => (
    <Switch>
        <Route
            path={ROUTES.FEATURES}
            render={() => <Redirect to={ROUTES.HOME} />}
        />
        <Route path={ROUTES.PRODUCT_EXPLORER} component={ProductExplorer} />
        <Route path={ROUTES.PRODUCT_ANALYZER} component={ProductAnalyzer} />
        <Route path={ROUTES.PRODUCT_WIDGET} component={ProductWidget} />
        <Route path={ROUTES.PRODUCT_SHOWCASE} component={ProductShowcase} />
        <Route
            path={ROUTES.PRODUCT_MARKET_ANALYSER}
            component={ProductMarketAnalyser}
        />
        <Route path={ROUTES.PRODUCT_PROSPECTS} component={ProductProspect} />
        <Route path={ROUTES.PRODUCT_API} component={ProductApi} />
        <Route path={ROUTES.PRICING_PRO} component={Pricing} />
        <Route path={ROUTES.PRICING} component={Pricing} />
        <Route path={ROUTES.DVF} component={Dvf} />
        <Route path={ROUTES.PATRIM} component={Patrim} />

        <Route path={ROUTES.CGU} component={Cgu} />
        <Route path={ROUTES.CGV} component={Cgv} />
        <Route path={ROUTES.LEGAL_TERMS} component={LegalTerms} />
        <Route path={ROUTES.CONTACT} component={Contact} />
        <Route path={ROUTES.WELCOME} component={Welcome} />
        <Route
            path={ROUTES.SALES_TX}
            render={() => <Redirect to={ROUTES.TRANSACTION_EXPLORER_SEARCH} />}
        />
        <Route
            path={ROUTES.MAP}
            render={() => <Redirect to={ROUTES.TRANSACTION_EXPLORER_SEARCH} />}
        />

        {/* AGENTS DIRECTORY */}

        <Route
            path={ROUTES.AGENCIES_AND_AGENTS_DIRECTORY_RESULTS_ADDRESS}
            component={AgentsDirectoryResults}
        />
        <Route
            path={ROUTES.AGENCIES_AND_AGENTS_DIRECTORY_RESULTS_STREET}
            component={AgentsDirectoryResults}
        />
        <Route
            path={ROUTES.AGENCIES_AND_AGENTS_DIRECTORY_RESULTS_DISTRICT}
            component={AgentsDirectoryResults}
        />
        <Route
            path={ROUTES.AGENCIES_AND_AGENTS_DIRECTORY_RESULTS_CITY}
            component={AgentsDirectoryResults}
        />
        <Route
            path={ROUTES.AGENCIES_AND_AGENTS_DIRECTORY_RESULTS_DEPARTMENT}
            component={AgentsDirectoryResults}
        />
        <Route
            path={ROUTES.AGENCIES_AND_AGENTS_DIRECTORY_RESULTS_REGION}
            component={AgentsDirectoryResults}
        />
        <Route
            path={ROUTES.AGENCIES_AND_AGENTS_DIRECTORY}
            component={AgentsDirectory}
        />

        {/* ACCOUNT */}
        <Route path={ROUTES.PROFILE} component={Account} />
        <Route path={ROUTES.SECURITY} component={Account} />
        <Route path={ROUTES.SUBSCRIPTION} component={Account} />
        <Route path={ROUTES.PARAMETERS} component={Account} />
        <Route path={ROUTES.TEMPLATES} component={Account} />

        {/* ACCESS MANAGEMENT */}
        <Route path={ROUTES.ACCESS_MANAGEMENT} component={AccessManagement} />

        <Route path={ROUTES.TRANSACTION_EXPLORER_SEARCH} component={Product} />

        <Route path={ROUTES.MARKET_ANALYSER} component={Product} />

        <Route path={ROUTES.PROPERTY_EXPLORER_SEARCH} component={Product} />
        <Route path={ROUTES.PROPERTY_EXPLORER_REPORT} component={Product} />
        <Route
            path={ROUTES.PROPERTY_EXPLORER_REPORT_ONLINE}
            component={Product}
        />

        {/* VALUATION FORM */}
        <Route path={ROUTES.VALUATION_ADDRESS} component={ValuationForm} />
        <Route path={ROUTES.VALUATION_PROPERTY} component={ValuationForm} />
        <Route path={ROUTES.VALUATION_NBROOMS} component={ValuationForm} />
        <Route path={ROUTES.VALUATION_SURFACE} component={ValuationForm} />
        <Route path={ROUTES.VALUATION_ISPRO} component={ValuationForm} />
        <Route path={ROUTES.VALUATION_CATEGORY} component={ValuationForm} />
        <Route
            path={ROUTES.VALUATION_PROJECT_STATUS}
            component={ValuationForm}
        />
        <Route path={ROUTES.VALUATION_LOADING} component={ValuationForm} />
        <Route path={ROUTES.VALUATION_RESULT} component={ValuationForm} />

        <Route path={ROUTES.LOGIN} component={Login} />
        <Route path={ROUTES.SIGN_UP} component={SignUp} />
        <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForget} />
        <Route path={ROUTES.ACTION} component={ActionHandler} />
        <Route path={ROUTES.PAYMENT_CHECKOUT} component={PaymentCheckout} />
        <Route path={ROUTES.NPS_THANKS} component={NpsThanks} />
        <Route path={ROUTES.NPS_FEEDBACK} component={NpsFeedback} />

        {/* MARKET EXPLORER */}
        <Route
            path={ROUTES.MARKET_EXPLORER_SHORT_ADDRESS}
            component={Address}
        />
        <Route path={ROUTES.MARKET_EXPLORER_ADDRESS} component={Address} />
        <Route path={ROUTES.MARKET_EXPLORER_STREET} component={Street} />
        <Route path={ROUTES.MARKET_EXPLORER_DISTRICT} component={District} />
        <Route path={ROUTES.MARKET_EXPLORER_CITY} component={City} />
        <Route
            path={ROUTES.MARKET_EXPLORER_DEPARTMENT}
            component={Department}
        />
        <Route path={ROUTES.MARKET_EXPLORER_REGION} component={Region} />
        <Route path={ROUTES.MARKET_EXPLORER} component={Country} />

        {/* VALUATION WIDGET */}
        <Route path={ROUTES.VALUATION_WIDGET} component={Product} />

        {/* CONTACTS */}
        <Route path={ROUTES.LEADS} component={Product} />
        <Route path={ROUTES.PROSPECTS} component={Product} />

        {/* SHOWCASE */}
        <Route path={ROUTES.SHOWCASE_CATEGORY} component={ShowcaseForm} />
        <Route path={ROUTES.SHOWCASE_IS_INDEPENDENT} component={ShowcaseForm} />
        <Route path={ROUTES.SHOWCASE_NETWORK} component={ShowcaseForm} />
        <Route path={ROUTES.SHOWCASE_NAME} component={ShowcaseForm} />
        <Route path={ROUTES.SHOWCASE_LOCATION} component={ShowcaseForm} />
        <Route path={ROUTES.SHOWCASE_COVERAGE} component={ShowcaseForm} />
        <Route path={ROUTES.SHOWCASE_SIRET} component={ShowcaseForm} />
        <Route path={ROUTES.SHOWCASE_REVIEW} component={ShowcaseReview} />
        <Route path={ROUTES.SHOWCASE_PUBLIC} component={PublicShowcase} />
        <Route path={ROUTES.SHOWCASE} component={Product} />

        {/* BLOG */}
        <Route path={ROUTES.BLOG_CADASTRE} component={CadastreContent} />
        <Route path={ROUTES.BLOG_POST} component={BlogPost} />
        <Route path={ROUTES.BLOG_HOME} component={BlogHome} />

        {/* LANDING PAGES */}
        <Route path={ROUTES.APPRAISAL_SOFTWARE} component={AppraisalSoftware} />
        <Route path={ROUTES.PROPERTY_VALUATION} component={PropertyValuation} />
        <Route
            path={ROUTES.LANDING_INDEPENDANT_AGENT}
            component={IndependantAgent}
        />
        <Route
            path={ROUTES.LANDING_REAL_ESTATE_AGENT}
            component={RealEstateAgent}
        />
        <Route path={ROUTES.EMBED_DVF} component={EmbedDvf} />

        {/* ---- REDIRECTS ---- */}
        {/* AGENT DIRECTORY COMING SOON */}
        <Route
            path={ROUTES.DEPRECATED_AGENT_DIRECTORY_COMING_SOON}
            render={() => <Redirect to={ROUTES.PRODUCT_SHOWCASE} />}
        />

        {/* LANDING PAGES */}
        <Route
            path={ROUTES.DEPRECATED_LANDING_INDEPENDANT_AGENT}
            render={() => <Redirect to={ROUTES.LANDING_INDEPENDANT_AGENT} />}
        />
        <Route
            path={ROUTES.DEPRECATED_LANDING_REAL_ESTATE_AGENT}
            render={() => <Redirect to={ROUTES.LANDING_REAL_ESTATE_AGENT} />}
        />
        {/* PRODUCTS */}
        <Route
            path={ROUTES.DEPRECATED_PRODUCT_ANALYZER}
            render={() => <Redirect to={ROUTES.PRODUCT_ANALYZER} />}
        />
        <Route
            path={ROUTES.DEPRECATED_PRODUCT_EXPLORER}
            render={() => <Redirect to={ROUTES.PRODUCT_EXPLORER} />}
        />
        <Route
            path={ROUTES.DEPRECATED_PRODUCT_WIDGET}
            render={() => <Redirect to={ROUTES.PRODUCT_WIDGET} />}
        />

        <Route exact path={ROUTES.HOME_PRO} component={HomePro} />
        <Route exact path={ROUTES.HOME} component={Home} />

        <Route path={ROUTES.SITEMAP} component={Sitemap} />
        <Route path={ROUTES.REDIRECT} component={RedirectPage} />
        <Route path={ROUTES.PAGE_NOT_FOUND} component={ErrorPage} />
        <Route path="*" component={ErrorPage} />
    </Switch>
);

export default Routes;
