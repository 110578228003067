import request from '../lib/request';

export const createUser = body =>
    request.post(`api/user`, body).then(({ data }) => data);

export const getUserAccountInfo = () =>
    request.get(`api/user/account-info`).then(({ data }) => data);

export const updateUserAccountInfo = body =>
    request.post(`api/user/account-info`, body).then(({ data }) => data);

export const updateUserType = body =>
    request.post(`api/user/type`, body).then(({ data }) => data);

export const updateUserAvatar = body =>
    request.post(`api/user/avatar`, body).then(({ data }) => data);

export const getUserPermissionLevel = () =>
    request.get(`api/user/permission`).then(({ data }) => data);

export const updateUserEmail = body =>
    request.put(`api/user/email`, body).then(({ data }) => data);

export const addToApiWaitingList = body =>
    request.post(`api/user/api-waiting-list`, body).then(({ data }) => data);
