/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Disclosure } from '@headlessui/react';
import { withRouter, Link } from 'react-router-dom';
import * as ROUTES from 'constants/routingPaths';
import { compose } from 'recompose';
import withAuthUserContext from 'hoc/withAuthUserContext';
import withFirebase from 'hoc/withFirebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { light, thin } from '@fortawesome/fontawesome-svg-core/import.macro';
import { classNames } from 'lib/utils';
import Button from 'components/Forms/Button';
import ProductDropdown from './ProductDropdown/ProductDropdown';
import { decodeUrl } from 'lib/seo.utils';
import MobileMenuDefault from './MobileMenu/MobileMenuDefault';

class HeaderDefault extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPath: this.props.location.pathname,
            callToAction: null,
        };
    }

    async componentDidMount() {
        this.setState({ currentPath: this.props.location.pathname });
        this.setCallToAction();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.setCallToAction();
        }
    }

    setCallToAction = () => {
        let callToAction;
        switch (this.props.location.pathname) {
            case ROUTES.BLOG_CADASTRE:
                callToAction = {
                    label: 'Consulter le cadastre',
                    url: ROUTES.MARKET_EXPLORER,
                };
                break;
            case ROUTES.DVF:
                callToAction = {
                    label: 'Consulter les ventes DVF',
                    url: ROUTES.TRANSACTION_EXPLORER_SEARCH,
                };
                break;
            case ROUTES.PATRIM:
                callToAction = {
                    label: 'Consulter les ventes',
                    url: ROUTES.TRANSACTION_EXPLORER_SEARCH,
                };
                break;
            case ROUTES.APPRAISAL_SOFTWARE:
            case ROUTES.PRODUCT_ANALYZER:
            case ROUTES.PRODUCT_EXPLORER:
            case ROUTES.PRODUCT_SHOWCASE:
            case ROUTES.PRODUCT_WIDGET:
            case ROUTES.LANDING_INDEPENDANT_AGENT:
                callToAction = {
                    label: 'Inscrivez-vous gratuitement',
                    url: ROUTES.SIGN_UP,
                };
                break;
            default:
                callToAction = null;
                break;
        }
        this.setState({ callToAction });
    };

    getDefaultItems = () => {
        return [
            {
                title: 'Estimer un bien',
                subCategory: '',
                url: ROUTES.VALUATION_ADDRESS,
                isHighlighted: window.location.pathname.includes(
                    ROUTES.VALUATION_ADDRESS
                ),
            },
            {
                category: 'Explorer le marché',
                subCategory: '',
                title: 'Ventes passées (DVF)',
                description:
                    'Consultez toutes les ventes immobilières réalisées en France.',
                icon: thin('map-location-dot'),
                url: ROUTES.TRANSACTION_EXPLORER_SEARCH,
                isHighlighted: window.location.pathname.includes(
                    ROUTES.TRANSACTION_EXPLORER_SEARCH
                ),
            },
            {
                category: 'Explorer le marché',
                subCategory: '',
                title: 'Analyser une adresse',
                description:
                    "Obtenez toutes les informations immobilières sur l'adresse qui vous intéresse.",
                icon: thin('magnifying-glass-location'),
                url: ROUTES.MARKET_EXPLORER,
                isHighlighted: window.location.pathname.includes(
                    ROUTES.MARKET_EXPLORER
                ),
            },

            {
                subCategory: '',
                title: 'Trouver un agent',
                url: ROUTES.AGENCIES_AND_AGENTS_DIRECTORY,
                isHighlighted:
                    window.location.pathname.includes(
                        ROUTES.AGENCIES_AND_AGENTS_DIRECTORY
                    ) || window.location.pathname.includes(ROUTES.SHOWCASE),
            },
        ];
    };

    getDefaultMenu = () => {
        const items = this.getDefaultItems();

        return (
            <div className="hidden lg:flex items-center gap-5 h-full">
                {items
                    .filter(item => !item.category)
                    .map((item, index) => (
                        <Link
                            key={index}
                            to={item.url}
                            className={classNames(
                                'inline-flex text-gray-900 h-full  items-center px-1 pt-1 border-b-2 text-sm font-medium',
                                item.isHighlighted
                                    ? 'border-primary-500'
                                    : 'border-transparent hover:border-gray-200 hover:text-gray-700 '
                            )}
                        >
                            {item.title}
                        </Link>
                    ))}
                <ProductDropdown
                    position="right"
                    label="Explorer le marché"
                    items={items.filter(
                        item => item.category === 'Explorer le marché'
                    )}
                />
            </div>
        );
    };

    getProItems = () => {
        return [
            {
                category: 'Analyser',
                subCategory: '',
                title: 'Explorateur des ventes DVF',
                description:
                    'Consultez toutes les ventes immobilières réalisées en France.',
                icon: thin('map-location-dot'),
                url: ROUTES.PRODUCT_EXPLORER,
                isHighlighted: window.location.pathname.includes(
                    ROUTES.PRODUCT_EXPLORER
                ),
            },
            {
                category: 'Analyser',
                subCategory: '',
                title: 'Données de marché',
                description:
                    'Analysez en détail le marché immobilier de votre secteur géographique.',
                icon: thin('chart-mixed'),
                url: ROUTES.PRODUCT_MARKET_ANALYSER,
                isHighlighted: window.location.pathname.includes(
                    ROUTES.PRODUCT_MARKET_ANALYSER
                ),
            },
            {
                category: 'Analyser',
                subCategory: '',
                title: 'Analyseur de biens',
                description:
                    "Analysez n'importe quel bien immobilier, estimez sa valeur, générez des rapports détaillés.",
                icon: thin('magnifying-glass-location'),
                url: ROUTES.PRODUCT_ANALYZER,
                isHighlighted: window.location.pathname.includes(
                    ROUTES.PRODUCT_ANALYZER
                ),
            },
            {
                category: 'Prospecter',
                subCategory: '',
                title: 'Prospects à proximité',
                description:
                    'Accédez à la liste des prospects dans votre secteur géographique.',
                icon: thin('people-group'),
                url: ROUTES.PRODUCT_PROSPECTS,
                isHighlighted: window.location.pathname.includes(
                    ROUTES.PRODUCT_PROSPECTS
                ),
            },
            {
                category: 'Prospecter',
                subCategory: '',
                title: 'Vitrine immobilière',
                description:
                    'Présentez vos services pour être contacté par les vendeurs qui viennent sur Immo Data.',
                icon: thin('store'),
                url: ROUTES.PRODUCT_SHOWCASE,
                isHighlighted: window.location.pathname.includes(
                    ROUTES.PRODUCT_SHOWCASE
                ),
            },
            {
                category: 'Prospecter',
                subCategory: '',
                title: "Module d'estimation",
                description:
                    "Installez un module d'estimation sur votre site et récupérez les coordonnées de vos visiteurs.",
                icon: thin('magnet'),
                url: ROUTES.PRODUCT_WIDGET,
                isHighlighted: window.location.pathname.includes(
                    ROUTES.PRODUCT_WIDGET
                ),
            },
        ];
    };

    getProMenu = () => {
        const productItems = this.getProItems();

        return (
            <div className="hidden lg:flex items-center gap-5 h-full">
                <ProductDropdown
                    position="right"
                    label="Prospecter"
                    items={productItems.filter(
                        item => item.category === 'Prospecter'
                    )}
                />
                <ProductDropdown
                    position="right"
                    label="Analyser"
                    items={productItems.filter(
                        item => item.category === 'Analyser'
                    )}
                />
                <Link
                    to={ROUTES.PRODUCT_API}
                    className={classNames(
                        'hidden lg:inline-flex text-gray-900 h-full  items-center px-1 pt-1 border-b-2 text-sm font-medium',
                        window.location.pathname.includes(ROUTES.PRODUCT_API)
                            ? 'border-primary-500'
                            : 'border-transparent hover:border-gray-200 hover:text-gray-700 '
                    )}
                >
                    API immobilière
                </Link>
                <Link
                    to={ROUTES.PRICING_PRO}
                    className={classNames(
                        'hidden lg:inline-flex text-gray-900 h-full  items-center px-1 pt-1 border-b-2 text-sm font-medium',
                        window.location.pathname.includes(ROUTES.PRICING_PRO)
                            ? 'border-primary-500'
                            : 'border-transparent hover:border-gray-200 hover:text-gray-700 '
                    )}
                >
                    Tarifs
                </Link>
            </div>
        );
    };

    render() {
        const { open, isProPage, isMarketExplorer, logo } = this.props;

        const { callToAction } = this.state;

        const { isWindowScrolling } = this.props;
        const isMinimalistHeader = this.props.location.pathname.startsWith(
            '/estimation/'
        );

        return (
            <>
                <div className="mx-auto px-4 sm:px-6 lg:px-8 xl:px-8">
                    <div className="flex flex-wrap justify-between gap-y-3 min-h-[64px]">
                        {/* LEFT MENU */}
                        <div
                            className={classNames(
                                'flex-shrink-0 items-center sm:gap-6 lg:gap-6 xl:gap-6',
                                callToAction && isWindowScrolling
                                    ? 'hidden sm:flex'
                                    : 'flex'
                            )}
                        >
                            {logo}
                            {!isMinimalistHeader &&
                                (isProPage
                                    ? this.getProMenu()
                                    : this.getDefaultMenu())}
                        </div>
                        {/* RIGHT MENU */}
                        {callToAction && isWindowScrolling ? (
                            <div
                                className={classNames(
                                    'flex items-center justify-center',
                                    callToAction && isWindowScrolling
                                        ? 'w-full sm:w-min'
                                        : ''
                                )}
                            >
                                <Button
                                    to={callToAction.url}
                                    className="w-full "
                                >
                                    {callToAction.label}
                                </Button>
                            </div>
                        ) : (
                            !isMinimalistHeader && (
                                <div className="hidden lg:flex lg:items-center lg:gap-6">
                                    <div className="flex lg:items-center lg:gap-5">
                                        {!isProPage && (
                                            <div className="relative">
                                                <Button
                                                    to={ROUTES.HOME_PRO}
                                                    size={'sm'}
                                                    priority={'transparent'}
                                                    className=" text-pro-500"
                                                >
                                                    Services Pro
                                                </Button>
                                            </div>
                                        )}
                                        <div className="relative">
                                            <Button
                                                priority="outlined"
                                                onClick={() =>
                                                    decodeUrl(
                                                        ROUTES.LOGIN_BASE64
                                                    )
                                                }
                                                size={'sm'}
                                            >
                                                Se connecter
                                            </Button>
                                        </div>
                                        <div className="relative">
                                            <Button
                                                onClick={() =>
                                                    decodeUrl(
                                                        ROUTES.SIGN_UP_BASE64
                                                    )
                                                }
                                                size={'sm'}
                                            >
                                                S'inscrire
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            )
                        )}
                        {/* MOBILE MENU BUTTON */}
                        {!(callToAction && isWindowScrolling) && (
                            <div className="-mr-2 flex items-center lg:hidden">
                                <Disclosure.Button
                                    className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none"
                                    aria-label="menu"
                                >
                                    {open ? (
                                        <FontAwesomeIcon
                                            icon={light('xmark')}
                                            className="block h-6 w-6"
                                            aria-hidden="true"
                                        />
                                    ) : (
                                        <FontAwesomeIcon
                                            icon={light('bars')}
                                            className="block h-6 w-6"
                                            aria-hidden="true"
                                        />
                                    )}
                                </Disclosure.Button>
                            </div>
                        )}
                    </div>
                </div>
                {/* MOBILE MENU */}
                {!(callToAction && isWindowScrolling) &&
                    !(isMarketExplorer && isWindowScrolling) && (
                        <MobileMenuDefault
                            items={
                                isProPage
                                    ? this.getProItems()
                                    : this.getDefaultItems()
                            }
                            isProPage={isProPage}
                        />
                    )}
            </>
        );
    }
}

export default compose(
    withRouter,
    withAuthUserContext,
    withFirebase
)(HeaderDefault);
