import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Disclosure } from '@headlessui/react';
import { classNames } from 'lib/utils';
import { compose } from 'recompose';
import withAuthUserContext from 'hoc/withAuthUserContext';
import Button from 'components/Forms/Button';
import * as ROUTES from 'constants/routingPaths';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { decodeUrl } from 'lib/seo.utils';

class MobileMenuDefault extends Component {
    render() {
        const { items, isProPage } = this.props;

        const itemsCategories = [...new Set(items.map(item => item.category))];

        return (
            <Disclosure.Panel className="lg:hidden w-full bg-white z-20 shadow-md absolute">
                <>
                    <div className="pt-2 pb-3 space-y-1">
                        {isProPage && (
                            <>
                                <Disclosure.Button
                                    as={Link}
                                    to={ROUTES.PRICING_PRO}
                                    className={classNames(
                                        ' block pl-3 pr-4 py-2 border-l-4 text-sm font-medium',
                                        window.location.pathname.includes(
                                            ROUTES.PRICING_PRO
                                        )
                                            ? 'bg-primary-50 border-primary-500 text-primary-700'
                                            : 'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700'
                                    )}
                                >
                                    Tarifs
                                </Disclosure.Button>
                                <Disclosure.Button
                                    as={Link}
                                    to={ROUTES.PRODUCT_API}
                                    className={classNames(
                                        ' block pl-3 pr-4 py-2 border-l-4 text-sm font-medium',
                                        window.location.pathname.includes(
                                            ROUTES.PRODUCT_API
                                        )
                                            ? 'bg-primary-50 border-primary-500 text-primary-700'
                                            : 'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700'
                                    )}
                                >
                                    API immobilières
                                </Disclosure.Button>
                            </>
                        )}
                        {itemsCategories.map((category, index) => (
                            <div key={index}>
                                {category && (
                                    <p className="bg-gray-50 py-2 px-3 text-sm w-full font-semibold text-gray-700">
                                        {category}
                                    </p>
                                )}
                                {items
                                    .filter(item => item.category === category)
                                    .map((item, i) => (
                                        <Disclosure.Button
                                            key={i}
                                            as="a"
                                            href={item.url}
                                            className={classNames(
                                                ' block pl-3 pr-4 py-2 border-l-4 text-sm font-medium',
                                                item.isHighlighted
                                                    ? 'bg-primary-50 border-primary-500 text-primary-700'
                                                    : 'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700'
                                            )}
                                        >
                                            {item.title}
                                        </Disclosure.Button>
                                    ))}
                            </div>
                        ))}
                        {!isProPage && (
                            <div className="border-t border-gray-200 pt-3">
                                <Disclosure.Button
                                    as="a"
                                    href={ROUTES.HOME_PRO}
                                    className={classNames(
                                        ' block pl-3 pr-4 py-2 border-l-4 text-sm font-medium text-pro-500',
                                        window.location.pathname.includes(
                                            ROUTES.HOME_PRO
                                        )
                                            ? 'bg-primary-50 border-primary-500 text-primary-700'
                                            : 'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700'
                                    )}
                                >
                                    Services pro
                                    <FontAwesomeIcon
                                        icon={light('arrow-right-to-bracket')}
                                        className="pl-1.5 h-4 w-4"
                                        aria-hidden="true"
                                    />
                                </Disclosure.Button>
                            </div>
                        )}
                    </div>
                    <div className="pt-4 pb-3 border-t border-gray-200">
                        <div className=" flex flex-row justify-between pl-3 pr-4 py-2 gap-3 ">
                            <Button
                                priority="outlined"
                                onClick={() => decodeUrl(ROUTES.LOGIN_BASE64)}
                                size={'sm'}
                                className="w-full"
                            >
                                Se connecter
                            </Button>

                            <Button
                                onClick={() => decodeUrl(ROUTES.SIGN_UP_BASE64)}
                                className="w-full"
                            >
                                S'inscrire
                            </Button>
                        </div>
                    </div>
                </>
            </Disclosure.Panel>
        );
    }
}

export default compose(withAuthUserContext)(MobileMenuDefault);
